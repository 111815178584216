import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useContext } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { MdDelete } from 'react-icons/md';
import AddManagerAuth from '@/components/adminboard/AddManagerAuth';
import UpdateCompanyInfoButton from '@/components/adminboard/UpdateCompanyInfoButton';
import UserContext from '@/contexts/UserContext';

import DatabaseContext from '@/contexts/DatabaseContext';
import Footer from '@/components/Footer';
import PopConfirmModal from '../../modals/PopConfirmModal';
import LoadingScreen from '../../components/router/LoadingScreen';
import TopNavbar from '../../components/dashboard/TopNavbar';

const Adminboard = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [companyInfo, setCompanyInfo] = useState();
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext);

  const { updateUserInfo } = useContext(DatabaseContext);

  useEffect(() => {
    const usersRef = 'users';
    const companyRef = 'companies';
    const socketRef = '/.info/connected';

    firebase
      .database()
      .ref(socketRef)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          setLoading(false);
          firebase.database().ref(socketRef).off();
        }
      });

    firebase
      .database()
      .ref(usersRef)
      .orderByChild('email')
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const usersArr = [];
          const data = snapshot.val();
          Object.keys(data).forEach((key) => {
            if (
              data[key].email &&
              user.adminCompanyId === data[key].managerCompanyId
            ) {
              usersArr.push(data[key]);
            }
          });
          setUsers(usersArr);
        }
      });

    firebase
      .database()
      .ref(`companies/${user.adminCompanyId}`)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const data = snapshot.val();
          setCompanyInfo(data);
        }
      });

    return () => {
      firebase.database().ref(usersRef).off();
      firebase.database().ref(companyRef).off();
    };
  }, []);

  const deleteManagerAuth = async (uid) => {
    const userInfo = users.find((x) => x.uid === uid);
    userInfo.managerCompanyId = null;
    await updateUserInfo(userInfo);
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div>
        <Helmet>
          <title>
            {t('adminboard.title')} | {t('shared.appName')}
          </title>
        </Helmet>

        <TopNavbar />
        <h1 className="text-center text-5xl font-bold mt-4">
          {t('adminboard.title')}
        </h1>

        <div className="container mt-12 px-12 xl:px-0 flex justify-around ">
          <div>
            <AddManagerAuth managerCompanyId={user.adminCompanyId} />
            {users.length > 0 ? (
              <table className="text-2xl table-auto ml-auto mr-auto">
                <caption className="px-4 py-2 text-2xl font-bold">
                  Manager List
                </caption>
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-emerald-600 border-solid border-2 border-white">
                      Email
                    </th>
                    <th className="px-4 py-2 text-emerald-600 border-solid border-2 border-white">
                      Remove Manager
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((x) => (
                    <tr key={x.uid}>
                      <td className="border-solid border-2 border-white px-4 py-2 text-emerald-600 font-medium">
                        {x.email}
                      </td>
                      <td className="border-solid border-2 border-white px-4 py-2 text-emerald-600 font-medium">
                        <PopConfirmModal
                          title="Are you sure?"
                          onConfirm={() => {
                            deleteManagerAuth(x.uid);
                          }}
                        >
                          <MdDelete className="m-auto" />
                        </PopConfirmModal>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h1 className="font-bold">No Manager Users</h1>
            )}
          </div>

          <div>
            <UpdateCompanyInfoButton companyInfo={companyInfo} />
            <table className="text-3xl table-auto ml-auto mr-auto">
              <caption className="px-4 py-2 text-4xl font-bold">
                Company Info
              </caption>

              <tbody>
                {companyInfo &&
                  Object.keys(companyInfo)
                    .filter((x) => x !== 'id')
                    .map((key) => (
                      <tr key={key}>
                        <td className="border-solid border-2 border-white px-4 py-2 text-emerald-600 font-medium">
                          {key}
                        </td>
                        <td className="border-solid border-2 border-white px-4 py-2 text-emerald-600 font-medium">
                          {key === 'logo' ? (
                            companyInfo[key] ? (
                              <img
                                className="w-20"
                                src={companyInfo[key]}
                                alt="company logo"
                              />
                            ) : null
                          ) : (
                            companyInfo[key]
                          )}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Adminboard;
