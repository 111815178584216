import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'gatsby-plugin-firebase';
import Footer from '@/components/Footer';
import ShortUniqueId from 'short-unique-id';
import Button from '../../components/shared/Button';
import LoadingScreen from '../../components/router/LoadingScreen';
import ResumePreview from '../../components/dashboard/ResumePreview';
import TopNavbar from '../../components/dashboard/TopNavbar';
import ModalContext from '../../contexts/ModalContext';
import initialState from '../../data/initialState.json';

const Private = ({ user }) => {
  const maxPrivetCV = 3;
  const { t } = useTranslation();
  const [resumes, setResumes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [invited, setInvited] = useState(false);
  const { emitter, events } = useContext(ModalContext);
  const dictionary = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('');
  const uuid = new ShortUniqueId({ dictionary });

  useEffect(() => {
    const resumesRef = 'resumes';
    const usersRef = 'users';
    const socketRef = '/.info/connected';
    const { currentUser } = firebase.auth();

    firebase
      .database()
      .ref(socketRef)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          setLoading(false);
          firebase.database().ref(socketRef).off();
        }
      });

    firebase
      .database()
      .ref(usersRef)
      .orderByChild('temp')
      .equalTo(true)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const data = snapshot.val();
          Object.keys(data).forEach((key) => {
            if (data[key].email === currentUser.email) {
              setInvited(true);
            }
          });
        }
      });

    firebase
      .database()
      .ref(resumesRef)
      .orderByChild('user')
      .equalTo(user.uid)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const resumesArr = [];
          const arr = [];
          const data = snapshot.val();
          Object.keys(data).forEach((key) => resumesArr.push(data[key]));
          for (const elem of resumesArr) {
            if (!elem.companyId) {
              arr.push(elem);
            }
          }
          setResumes(arr);
        }
      });

    firebase
      .database()
      .ref(resumesRef)
      .orderByChild('user')
      .equalTo(user.uid)
      .on('child_removed', (snapshot) => {
        if (snapshot.val()) {
          setResumes(resumes.filter((x) => x.id === snapshot.val().id));
        }
      });

    return () => {
      firebase.database().ref(resumesRef).off();
    };
  }, [user]);

  if (loading) {
    return <LoadingScreen />;
  }

  const handleCreatePrivateCV = () => {
    const id = uuidv4();
    const sId = uuid();
    const createdAt = firebase.database.ServerValue.TIMESTAMP;
    const resume = {
      ...initialState,
      id,
      sId,
      name: 'demo',
      user: user.uid,
      preview: '',
      profile: {
        ...initialState.profile,
        firstName: '',
        lastName: '',
      },
      createdAt,
      updatedAt: createdAt,
    };

    const keyPair = { id };
    firebase.database().ref(`keyPairs/${sId}`).set(keyPair);
    firebase.database().ref(`resumes/${id}`).set(resume);
  };

  const handleAddCompany = () => {
    const adminEmail = user.email;
    emitter.emit(events.ADD_COMPANY_MODAL, { adminEmail });
  };

  return (
    <>
      <div>
        <Helmet>
          <title>
            {t('private.title')} | {t('shared.appName')}
          </title>
          <link rel="canonical" href="https://happycv.se/app/private" />
        </Helmet>

        <TopNavbar />

        <h1 className="text-center text-5xl font-bold mt-4">
          {t('private.title')}
        </h1>
        <h2 className="text-center text-2xl mt-4">
          You can create a few private resuems here. To manage more CVs for a
          company, you need to add company info first.
        </h2>

        {resumes.length < maxPrivetCV && (
          <Button
            title=""
            className="ml-auto mr-auto mt-8"
            onClick={handleCreatePrivateCV}
          >
            {t('private.createCV')}
          </Button>
        )}

        {!user.adminCompanyId && !user.managerCompanyId && !invited && (
          <Button
            title=""
            className="ml-auto mr-auto mt-8"
            onClick={handleAddCompany}
          >
            {t('private.addCompany')}
          </Button>
        )}

        <div className="container mt-12 px-12 xl:px-0">
          <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-8">
            {resumes.map((x) => (
              <ResumePreview key={x.id} resume={x} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Private;
