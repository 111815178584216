import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import Footer from '@/components/Footer';
import { useLocation } from '@reach/router';
import LoadingScreen from '../../components/router/LoadingScreen';
import TopNavbar from '../../components/dashboard/TopNavbar';
import ResumePreviewReadOnly from '../../components/dashboard/ResumePreviewReadOnly';
import Input from '../../components/shared/Input';
import { fuseOptions } from '../../utils';

const DashboardReadOnly = () => {
  const path = useLocation().pathname;
  const { t } = useTranslation();
  const [resumes, setResumes] = useState([]);
  const [rawResumes, setRawResumes] = useState([]);
  const [title, setTitle] = useState('');
  const [homepage, setHomepage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const resumesRef = 'resumes';
    const companiesRef = 'companies';
    const socketRef = '/.info/connected';

    firebase
      .database()
      .ref(socketRef)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          setLoading(false);
          firebase.database().ref(socketRef).off();
        }
      });
    const companyId = path.split('/').pop();

    if (companyId !== '') {
      firebase
        .database()
        .ref(resumesRef)
        .orderByChild('companyId')
        .equalTo(companyId)
        .on('value', (snapshot) => {
          if (snapshot.val()) {
            const resumesArr = [];
            const data = snapshot.val();
            Object.keys(data).forEach((key) => {
              resumesArr.push(data[key]);
            });
            const arrShow = [];
            for (const elem of resumesArr) {
              if (elem.show2readonly) {
                arrShow.push(elem);
              }
            }
            setRawResumes(arrShow);
            setResumes(arrShow);
          }
        });
    }

    firebase
      .database()
      .ref(companiesRef)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const arr = [];
          const data = snapshot.val();
          Object.keys(data).forEach((key) => arr.push(data[key]));
          for (const elem of arr) {
            if (elem.id === companyId) {
              setTitle(elem.name);
              setHomepage(elem.website);
            }
          }
        }
      });

    return () => {
      firebase.database().ref(resumesRef).off();
      firebase.database().ref(companiesRef).off();
    };
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  const mappedResumes = rawResumes.map((resume) => ({
    ...resume,
    skills: resume.skills.items?.map((item) => item.name).join(','),
  }));

  const fuse = new Fuse(mappedResumes, fuseOptions);

  const handleSearch = (event) => {
    const { value } = event.target;

    if (value.length === 0) {
      setResumes(rawResumes);
      return;
    }

    const results = fuse.search(value);
    const extractedResumes = results.map((result) => result.item);
    setResumes(extractedResumes);
  };

  return (
    <>
      <div>
        <Helmet>
          <title>
            {t('dashboard.title')} | {t('shared.appName')}
          </title>
          <link rel="canonical" href="https://happycv.se/app/dashboard" />
        </Helmet>

        <TopNavbar />

        <h1 className="text-center text-5xl font-bold mt-4">{title}</h1>
        <h1 className="text-center text-2xl mt-4">{homepage}</h1>

        <Input
          type="text"
          className="my-8 text-2xl w-1/3 mx-auto"
          placeholder="Filter by space separated keywords. (e.g. Python Java) "
          onChange={handleSearch}
        />

        <div className="container mt-12 px-12 xl:px-0">
          <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-8">
            {resumes.map((x) => (
              <ResumePreviewReadOnly key={x.id} resume={x} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DashboardReadOnly;
