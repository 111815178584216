import React, { memo, useContext } from 'react';
import { AiOutlineUserAdd } from 'react-icons/ai';
import Button from '@/components/shared/Button';
import ModalContext from '@/contexts/ModalContext';

const AddManagerAuth = ({ managerCompanyId }) => {
  const { emitter, events } = useContext(ModalContext);

  const handleClick = () => {
    emitter.emit(events.ADD_MANAGER_AUTH_MODAL, { managerCompanyId });
  };

  return (
    <Button
      outline
      icon={AiOutlineUserAdd}
      onClick={handleClick}
      className="m-auto mb-8"
    >
      Add Manager
    </Button>
  );
};

export default memo(AddManagerAuth);
