import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { navigate } from 'gatsby';
import Footer from '@/components/Footer';
import Fuse from 'fuse.js';
import LoadingScreen from '../../components/router/LoadingScreen';
import ResumePreview from '../../components/dashboard/ResumePreview';
import TopNavbar from '../../components/dashboard/TopNavbar';
import Button from '../../components/shared/Button';
import Input from '../../components/shared/Input';
import { fuseOptions } from '../../utils';

const DashboardArchive = ({ user }) => {
  const { t } = useTranslation();
  const [resumes, setResumes] = useState([]);
  const [rawResumes, setRawResumes] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleGoToDashboard = () => navigate(`/app/dashboard`);

  useEffect(() => {
    const resumesRef = 'resumes';
    const companiesRef = 'companies';
    const socketRef = '/.info/connected';

    firebase
      .database()
      .ref(socketRef)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          setLoading(false);
          firebase.database().ref(socketRef).off();
        }
      });

    const companyId = user.managerCompanyId || user.adminCompanyId || '';

    if (companyId !== '') {
      firebase
        .database()
        .ref(resumesRef)
        .orderByChild('companyId')
        .equalTo(companyId)
        .on('value', (snapshot) => {
          if (snapshot.val()) {
            const data = snapshot.val();
            const resumesArr = Object.entries(data)
              .map(([, resume]) => resume)
              .filter((resume) => !resume.public);
            setRawResumes(resumesArr);
            setResumes(resumesArr);
          }
        });
    }

    firebase
      .database()
      .ref(resumesRef)
      .orderByChild('companyId')
      .equalTo(companyId)
      .on('child_removed', (snapshot) => {
        if (snapshot.val()) {
          setRawResumes(rawResumes.filter((x) => x.id === snapshot.val().id));
          setResumes(rawResumes);
        }
      });

    return () => {
      firebase.database().ref(resumesRef).off();
      firebase.database().ref(companiesRef).off();
    };
  }, [user]);

  if (loading) {
    return <LoadingScreen />;
  }

  const mappedResumes = rawResumes.map((resume) => ({
    ...resume,
    skills: resume.skills.items?.map((item) => item.name).join(','),
  }));

  const fuse = new Fuse(mappedResumes, fuseOptions);

  const handleSearch = (event) => {
    const { value } = event.target;

    if (value.length === 0) {
      setResumes(rawResumes);
      return;
    }

    const results = fuse.search(value);
    const extractedResumes = results.map((result) => result.item);
    setResumes(extractedResumes);
  };

  return (
    <>
      <div>
        <Helmet>
          <title>
            {t('dashboard.archive')} | {t('shared.appName')}
          </title>
          <link rel="canonical" href="https://happycv.se/app/dashboard" />
        </Helmet>

        <TopNavbar />

        <h1 className="text-center text-5xl font-bold mt-4">
          {t('dashboard.archive')}
        </h1>
        <h2 className="text-center text-2xl my-5">
          Number of Archived resumes: {resumes.length}
        </h2>

        <Input
          type="text"
          className="mb-8 text-2xl w-1/3 mx-auto"
          placeholder="Filter by space separated keywords. (e.g. Python Java) "
          onChange={handleSearch}
        />

        <div className="my-15 container flex">
          <Button title="" className="mx-auto" onClick={handleGoToDashboard}>
            {t('dashboard.title')}
          </Button>
        </div>

        <div className="container mt-12 px-12 xl:px-0">
          <div className="grid grid-cols-1 gap-x-6 gap-y-10 md:grid-cols-4 lg:grid-cols-6 lg:gap-x-8">
            {resumes.map((x) => (
              <ResumePreview key={x.id} resume={x} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DashboardArchive;
