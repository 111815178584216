import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useContext } from 'react';
import firebase from 'gatsby-plugin-firebase';
import ModalContext from '@/contexts/ModalContext';
import PopConfirmModal from '@/modals/PopConfirmModal';
import DatabaseContext from '@/contexts/DatabaseContext';

import Footer from '@/components/Footer';
import AddCompanyButton from '@/components/superadminboard/AddCompanyButton';
import { AiFillEdit, AiFillDelete } from 'react-icons/ai';
import LoadingScreen from '../../components/router/LoadingScreen';
import TopNavbar from '../../components/dashboard/TopNavbar';

const Adminboard = () => {
  const { t } = useTranslation();
  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { emitter, events } = useContext(ModalContext);

  const { deleteCompanyById } = useContext(DatabaseContext);

  useEffect(() => {
    const companyRef = 'companies';
    const socketRef = '/.info/connected';

    firebase
      .database()
      .ref(socketRef)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          setLoading(false);
          firebase.database().ref(socketRef).off();
        }
      });

    firebase
      .database()
      .ref(`companies`)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const arr = [];
          const data = snapshot.val();
          Object.keys(data).forEach((key) => {
            arr.push(data[key]);
          });
          setCompanyList(arr);
        }
      });

    return () => {
      firebase.database().ref(companyRef).off();
    };
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div>
        <Helmet>
          <title>
            {t('adminboard.title')} | {t('shared.appName')}
          </title>
        </Helmet>

        <TopNavbar />

        <AddCompanyButton />

        <div className="container mt-12 px-12 xl:px-0 flex justify-around ">
          <div>
            {companyList.length > 0 ? (
              <table className="text-lg table-auto ml-auto mr-auto">
                <caption className="px-4 py-6 text-4xl font-bold">
                  Company List
                </caption>
                <thead>
                  <tr>
                    <th className="px-4 py-4 text-2xl border-solid border-2 border-white">
                      Company Name
                    </th>
                    <th className="px-4 py-4 text-2xl border-solid border-2 border-white">
                      City
                    </th>
                    <th className="px-4 py-4 text-2xl border-solid border-2 border-white">
                      Email
                    </th>
                    <th className="px-4 py-4 text-2xl border-solid border-2 border-white">
                      Phone
                    </th>
                    <th className="px-4 py-4 text-2xl border-solid border-2 border-white">
                      Website
                    </th>
                    <th className="px-4 py-4 text-2xl border-solid border-2 border-white">
                      Admin Email
                    </th>
                    <th className="px-4 py-4 text-2xl border-solid border-2 border-white">
                      Organization Code
                    </th>
                    <th className="px-4 py-4 text-2xl border-solid border-2 border-white">
                      Operation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {companyList.map((x, index) => (
                    <tr key={index}>
                      <td className="border-solid border-2 border-white px-4 py-4 text-xl font-medium">
                        {x.name}
                      </td>
                      <td className="border-solid border-2 border-white px-4 py-4 text-xl font-medium">
                        {x.city}
                      </td>
                      <td className="border-solid border-2 border-white px-4 py-4 text-xl font-medium">
                        {x.email}
                      </td>
                      <td className="border-solid border-2 border-white px-4 py-4 text-xl font-medium">
                        {x.phone}
                      </td>
                      <td className="border-solid border-2 border-white px-4 py-4 text-xl font-medium">
                        <a href={x.website} target="_blank" rel="noreferrer">
                          {x.website}
                        </a>
                      </td>
                      <td className="border-solid border-2 border-white px-4 py-4 text-xl font-medium">
                        {x.adminEmail}
                      </td>
                      <td className="border-solid border-2 border-white px-4 py-4 text-xl font-medium">
                        {x.orgNumber}
                      </td>
                      <td className="border-solid border-2 border-white px-4 py-4 text-xl font-medium">
                        <div className="flex justify-around">
                          <button
                            onClick={() => {
                              emitter.emit(events.UPDATE_COMPANY_INFO_MODAL, x);
                            }}
                          >
                            <AiFillEdit />
                          </button>
                          <PopConfirmModal
                            title="Are you sure?"
                            onConfirm={async () => {
                              await deleteCompanyById(x.id);
                            }}
                          >
                            <AiFillDelete className="m-auto" />
                          </PopConfirmModal>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h1 className="font-bold">No Admin Users</h1>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Adminboard;
