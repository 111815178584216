import { Redirect, Router } from '@reach/router';
import React, { memo } from 'react';
import Builder from './app/builder';
import Dashboard from './app/dashboard';
import DashboardArchive from './app/dashboardArchive';
import DashboardReadOnly from './app/dashboardReadOnly';
import Settings from './app/settings';
import Private from './app/private';
import Managerboard from './app/managerboard';
import SuperAdminboard from './app/superadminboard';
import Adminboard from './app/adminboard';
import NotFound from './404';
import PrivateRoute from '../components/router/PrivateRoute';
import Wrapper from '../components/shared/Wrapper';

const App = () => {
  const privateRoutes = [
    { path: '/app/settings', component: Settings, role: 'user' },
    { path: '/app/private', component: Private, role: 'user' },
    { path: '/app/managerboard', component: Managerboard, role: 'manager' },
    { path: '/app/dashboard', component: Dashboard, role: 'manager' },
    {
      path: '/app/dashboardArchive',
      component: DashboardArchive,
      role: 'manager',
    },
    { path: '/app/adminboard', component: Adminboard, role: 'admin' },
    {
      path: '/app/superadminboard',
      component: SuperAdminboard,
      role: 'superadmin',
    },
    {
      path: '/app/dashboard/readonly/:id',
      component: DashboardReadOnly,
      role: 'any',
    },
  ];
  return (
    <Wrapper>
      <Router>
        <Builder path="/app/builder/:id" />
        <Redirect noThrow from="/app" to="/app/dashboard" exact />
        {privateRoutes.map(({ path, component, role }) => (
          <PrivateRoute
            key={path}
            path={path}
            component={component}
            role={role}
          />
        ))}
        <NotFound default />
      </Router>
    </Wrapper>
  );
};
export default memo(App);
