import { MdOpenInNew } from 'react-icons/md';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';
import React from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import Markdown from '../shared/Markdown';
import * as styles from './ResumePreview.module.css';

const menuToggleDataTestIdPrefix = 'resume-preview-menu-toggle-';

const ResumePreviewReadOnly = ({ resume }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          companyWebsite
        }
      }
    }
  `);

  const getReadOnlyUrl = () => {
    const domain = site.siteMetadata.companyWebsite;
    const shortId = resume.sId;
    return `${domain}/r/${shortId}`;
  };

  const handleOpen = () => {
    window && window.open(getReadOnlyUrl(false));
  };

  return (
    <div className={styles.resume}>
      <div className={cx(styles.backdrop)}>
        <img src={resume.profile.photograph} alt={resume.name} />
      </div>
      <div className={cx(styles.page)}>
        <MdOpenInNew
          color="#fff"
          size="48"
          className="cursor-pointer"
          onClick={handleOpen}
        />
      </div>
      <div className={cx(styles.meta)}>
        <div>{resume.name}</div>
        {resume.comments && (
          <div className={styles.tooltip}>
            <IoIosInformationCircleOutline className="text-3xl mx-2" />
            <span className={styles.tooltiptext}>
              <Markdown className="markdown text-sm">
                {resume.comments.body}
              </Markdown>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResumePreviewReadOnly;

export { menuToggleDataTestIdPrefix };
