import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useContext } from 'react';
import firebase from 'gatsby-plugin-firebase';
import Footer from '@/components/Footer';
import UserContext from '@/contexts/UserContext';
import LoadingScreen from '../../components/router/LoadingScreen';
import ResumePreview from '../../components/dashboard/ResumePreview';
import TopNavbar from '../../components/dashboard/TopNavbar';

const Managerboard = () => {
  const { t } = useTranslation();
  const [resumes, setResumes] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const resumesRef = 'resumes';
    const socketRef = '/.info/connected';

    firebase
      .database()
      .ref(socketRef)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          setLoading(false);
          firebase.database().ref(socketRef).off();
        }
      });

    firebase
      .database()
      .ref(resumesRef)
      .orderByChild('user')
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const resumesArr = [];
          const data = snapshot.val();
          Object.keys(data).forEach((key) => {
            if (
              data[key].companyId &&
              (data[key].companyId === user.managerCompanyId ||
                data[key].companyId === user.adminCompanyId)
            ) {
              resumesArr.push(data[key]);
            }
          });
          setResumes(resumesArr);
        }
      });

    firebase
      .database()
      .ref(resumesRef)
      .orderByChild('user')
      .on('child_removed', (snapshot) => {
        if (snapshot.val()) {
          setResumes(resumes.filter((x) => x.id === snapshot.val().id));
        }
      });

    return () => {
      firebase.database().ref(resumesRef).off();
    };
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div>
        <Helmet>
          <title>
            {t('managerboard.title')} | {t('shared.appName')}
          </title>
          <link rel="canonical" href="https://happycv.se/app/adminboard" />
        </Helmet>

        <TopNavbar />

        <div className="container mt-12 px-12 xl:px-0">
          <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-8">
            {resumes.length > 0 ? (
              resumes.map((x) => (
                <ResumePreview
                  key={x.id}
                  resume={x}
                  showDeleteMenu={false}
                  showDuplicateMenu={false}
                  styleType="admin"
                />
              ))
            ) : (
              <h1 className="font-bold">No resumes</h1>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Managerboard;
